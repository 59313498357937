<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="缩略图" min-width="80" align="center">
				  <template slot-scope="scope">
					<img :src="scope.row.url" style="width: 30px;height: 30px;" @click="viewImage(scope.row,'url')">
				  </template>
				</el-table-column>
				<el-table-column label="广告名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="位置" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.position }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="跳转类型" min-width="110" align="center">
				  <template slot-scope="scope">
				   <el-tag v-if="scope.row.type === 0" size="mini" type="success">不跳转</el-tag>
				   <el-tag v-if="scope.row.type === 1" size="mini" type="success">文章</el-tag>
				   <el-tag v-if="scope.row.type === 2" size="mini" type="success">商品</el-tag>
				   <el-tag v-if="scope.row.type === 3" size="mini" type="success">分类</el-tag>
				   <el-tag v-if="scope.row.type === 4" size="mini" type="success">路径</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			<el-form-item label="广告位" prop="position_id">
				   <el-select v-model="form.position_id" placeholder="请选择广告位" size="medium">
					 <el-option v-for="(item,index) in positions" :key="index"
					   :label="item.name"
					   :value="item.id">
					 </el-option>
				   </el-select>
				</el-form-item>
			<el-form-item label="广告名称" prop="name">
				<el-input v-model="form.name" placeholder="请输入广告名称" type="text" clearable />
			</el-form-item>
			<el-form-item label="跳转类型" prop="type">
				   <el-select v-model="form.type" placeholder="请选择跳转类型" size="medium">
					 <el-option v-for="(item,index) in types" :key="index"
					   :label="item.name"
					   :value="item.id">
					 </el-option>
				   </el-select>
			</el-form-item>
			<el-form-item label="文章ID" prop="value" v-if="form.type == 1">
				<el-input v-model="form.value" placeholder="请输入文章ID" type="number" clearable @focus="selArticle"/>
				<small class="text-danger">为文章列表id</small>
			</el-form-item>
			<el-form-item label="商品ID" prop="value" v-if="form.type == 2">
				<el-input v-model="form.value" placeholder="请输入商品ID" type="number" clearable @focus="selGoods"/>
				<small class="text-danger">为商品列表id</small>
			</el-form-item>
			<el-form-item label="商品分类" prop="value" v-if="form.type == 3">
				<el-input v-model="form.value" placeholder="请输入商品分类ID" type="number" clearable @focus="selCates"/>
				<small class="text-danger">为商品分类id</small>
			</el-form-item>
			<el-form-item label="链接路径" prop="value" v-if="form.type == 4">
				<el-input v-model="form.value" placeholder="请输入链接路径" type="text" clearable/>
				<!-- <small class="text-danger">链接路径</small> -->
			</el-form-item>
			<el-form-item label="图片" prop="url">
				  <div class="border rounded flex-all mr-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
				  @click="chooseImage"
				  >
					<img :src="form.url" class="w-100 h-100" v-if="form.image_id">
					<i class="el-icon-plus text-muted" style="font-size: 30px;" v-else></i>
				  </div>
			</el-form-item>
			<el-form-item  label="状态">
				  <el-radio-group v-model="form.status">
					<el-radio :label="1">使用</el-radio>
					<el-radio :label="2">停用</el-radio>
				  </el-radio-group>
			</el-form-item>
			<el-form-item>
				<div class="w-100 text-danger">banner尺寸：750px*422px</div>
			</el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
		
		<!-- 选择用户 -->
		<el-dialog :visible.sync="openArticle" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-table
			  :data="articleData"
			  style="width: 100%">
			  <el-table-column
			  label="ID"
			  align="center"
			  prop="id">
			  </el-table-column>
			  <el-table-column
			  prop="url"
			  align="center"
			  label="封面">
			  <template slot-scope="scope">
			   <img :src="scope.row.url" style="width: 30px;height: 30px;cursor: pointer;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
			   <img src="@/assets/images/empty.png" style="width: 30px;height: 30px;cursor: pointer;" v-else>
			  </template>
			  </el-table-column>
			  <el-table-column
			  label="标题"
			  align="center"
			  prop="title">
			  </el-table-column>
			  <el-table-column
			  label="作者"
			  align="center"
			  prop="anthor">
			  </el-table-column>
			  <el-table-column
			  width="200"
			  align="right">
			  <template slot="header" slot-scope="scope">
				<el-input
				v-model="page.keyword"
				size="mini"
				placeholder="输入关键字搜索">
				  <el-button slot="append" icon="el-icon-search" size="mini" @click="getArticle"></el-button>
				</el-input>
			  </template>
			  <template slot-scope="scope">
				<el-button
				type="danger"
				size="mini"
				@click="handleSel(scope.$index, scope.row)">选择</el-button>
			  </template>
			  </el-table-column>
			</el-table>
			<!-- 分页 -->
			<span slot="footer" class="dialog-footer">
			  <div class="px-2" style="flex: 1;">
			   <pagination
				 :total="page.total"
				 auto-scroll
				 :page.sync="page.page"
				 :limit.sync="page.limit"
				 @pagination="getArticle"
			   />
			  </div>
			</span>
		</el-dialog>
		<!-- 选择商品 -->
		<el-dialog :visible.sync="openGoods" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-table
			  :data="goodsData"
			  style="width: 100%">
			  <el-table-column
			  label="ID"
			  align="center"
			  prop="id">
			  </el-table-column>
			  <el-table-column
			  prop="url"
			  align="center"
			  label="封面">
			  <template slot-scope="scope">
			   <img :src="scope.row.url" style="width: 30px;height: 30px;cursor: pointer;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
			   <img src="@/assets/images/empty.png" style="width: 30px;height: 30px;cursor: pointer;" v-else>
			  </template>
			  </el-table-column>
			  <el-table-column
			  label="名称"
			  align="center"
			  prop="name">
			  </el-table-column>
			  <el-table-column
			  label="价格"
			  align="center"
			  prop="price">
			  </el-table-column>
			  <el-table-column
			  label="市场价"
			  align="center"
			  prop="mktprice">
			  </el-table-column>
			  <el-table-column
			  width="200"
			  align="right">
			  <template slot="header" slot-scope="scope">
				<el-input
				v-model="goods_page.keyword"
				size="mini"
				placeholder="输入关键字搜索">
				  <el-button slot="append" icon="el-icon-search" size="mini" @click="getGoods"></el-button>
				</el-input>
			  </template>
			  <template slot-scope="scope">
				<el-button
				type="danger"
				size="mini"
				@click="handleGoodsSel(scope.$index, scope.row)">选择</el-button>
			  </template>
			  </el-table-column>
			</el-table>
			<!-- 分页 -->
			<span slot="footer" class="dialog-footer">
			  <div class="px-2" style="flex: 1;">
			   <pagination
				 :total="goods_page.total"
				 auto-scroll
				 :page.sync="goods_page.page"
				 :limit.sync="goods_page.limit"
				 @pagination="getGoods"
			   />
			  </div>
			</span>
		</el-dialog>
		<!-- 选择商品分类 -->
		<el-dialog :visible.sync="openCates" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-table
			  :data="catesData"
			  style="width: 100%">
			  <el-table-column
			  label="ID"
			  align="center"
			  prop="id">
			  </el-table-column>
			  <el-table-column
			  prop="url"
			  align="center"
			  label="封面">
			  <template slot-scope="scope">
			   <img :src="scope.row.url" style="width: 30px;height: 30px;cursor: pointer;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
			   <img src="@/assets/images/empty.png" style="width: 30px;height: 30px;cursor: pointer;" v-else>
			  </template>
			  </el-table-column>
			  <el-table-column
			  label="名称"
			  align="center"
			  prop="name">
			  </el-table-column>
			  <el-table-column
			  width="200"
			  align="right">
			  <template slot="header" slot-scope="scope">
				<el-input
				v-model="cates_page.keyword"
				size="mini"
				placeholder="输入关键字搜索">
				  <el-button slot="append" icon="el-icon-search" size="mini" @click="getCates"></el-button>
				</el-input>
			  </template>
			  <template slot-scope="scope">
				<el-button
				type="danger"
				size="mini"
				@click="handleCatesSel(scope.$index, scope.row)">选择</el-button>
			  </template>
			  </el-table-column>
			</el-table>
			<!-- 分页 -->
			<span slot="footer" class="dialog-footer">
			  <div class="px-2" style="flex: 1;">
			   <pagination
				 :total="cates_page.total"
				 auto-scroll
				 :page.sync="cates_page.page"
				 :limit.sync="cates_page.limit"
				 @pagination="getCates"
			   />
			  </div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'banners',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				positions:[],
				types:[
					{id:0,name:'不跳转'},
					{id:1,name:'文章'},
					{id:2,name:'商品'},
					{id:3,name:'分类'},
					{id:4,name:'路径'},
				],
				form:{
					name:'',
					position_id:1,
					type:0,
					image_id:'',
					url:'',
					value:'',
					sort:100,
					status:1,
					brief:'',
				},
				rules:{
					name: [
					  { required: true, message: '请输入广告名称', trigger: 'blur' }
					],
					type: [
					  { required: true, message: '请选择广告类型', trigger: 'blur' }
					],
					position_id: [
					  { required: true, message: '请选择广告位', trigger: 'blur' }
					],
				},
				page: {
					page: 1,
					limit: 10,
					total:0,
					keyword: '',
				},
				openArticle:false,
				articleData:[],
				goods_page: {
					page: 1,
					limit: 10,
					total:0,
					keyword: '',
				},
				openGoods:false,
				goodsData:[],
				cates_page: {
					page: 1,
					limit: 10,
					total:0,
					keyword: '',
				},
				openCates:false,
				catesData:[],
			}
		},
		mounted() {
			this.getPositions()
		},
		methods:{
			reset() {
			  this.form = {
			   name:'',
			   position_id:1,
			   type:0,
			   image_id:'',
			   url:'',
			   value:'',
			   sort:100,
			   status:1,
			   brief:'',
			  }
			  this.resetForm('form')
			},
			getPositions(){
				this.axios.get('/manage/position/all').then(res=>{
					if(res.status){
						this.positions = res.data
					}
				})
			},
			chooseImage(){
				this.app.chooseImage((res)=>{
				  this.form.image_id = res[0].id
				  this.form.url = res[0].url
				})
			},
			selArticle(){
				 this.getArticle()
				 this.$nextTick(()=>{
				   this.title = '文章列表'
				   this.openArticle = true
				 })
			},
			selGoods(){
				 this.getGoods()
				 this.$nextTick(()=>{
				   this.title = '商品列表'
				   this.openGoods = true
				 })
			},
			selCates(){
				this.getCates()
				this.$nextTick(()=>{
				  this.title = '分类列表'
				  this.openCates = true
				})
			},
			getArticle(){
				this.axios.get('/manage/article/list',{
				  params: {
					page:this.page.page,
					limit:this.page.limit,
					keyword:this.page.keyword,
				  }
				}).then(res=>{
				  this.page.total = res.total
				  this.articleData = res.data
				})
			},
			getGoods(){
				this.axios.get('/manage/goods/list',{
				  params: {
					page:this.goods_page.page,
					limit:this.goods_page.limit,
					keyword:this.goods_page.keyword,
				  }
				}).then(res=>{
				  this.goods_page.total = res.total
				  this.goodsData = res.data
				})
			},
			getCates(){
				this.axios.get('/manage/goods_cat/list',{
				  params: {
					page:this.cates_page.page,
					limit:this.cates_page.limit,
					keyword:this.cates_page.keyword,
				  }
				}).then(res=>{
				  this.cates_page.total = res.total
				  this.catesData = res.data
				})
			},
			handleSel(index,item){
				this.form.value = item.id
				this.openArticle = false
				this.title = '新增'
			},
			handleGoodsSel(index,item){
				this.form.value = item.id
				this.openGoods = false
				this.title = '新增'
			},
			handleCatesSel(index,item){
				this.form.value = item.id
				this.openCates = false
				this.title = '新增'
			}
		}
	}
</script>

<style>
</style>